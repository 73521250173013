import styled from "styled-components";
import Form from "./Form";

const Section = styled.section`
  width: 100%;
  background-color: var(--secondary);
`;

const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 100px 247px 100px 197px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 71px;
    font-family: var(--heading);
    font-weight: 100;
    line-height: 93%;
    letter-spacing: -0.01em;
    color: black;
    margin: 0;
  }

  @media (max-width: 1279px) {
    padding: 99px 0 124px;
    flex-direction: column;

    h1 {
      font-size: 60px;
      line-height: 110%;
      margin-bottom: 70px;
    }
  }

  @media (max-width: 1023px) {
    padding: 50px 30px 90px;

    h1 {
      font-size: 40px;
      line-height: 165%;
      margin-bottom: 49px;
    }
  }
`;

const Contact = () => {
  return (
    <Section id="contact">
      <Container>
        <h1>Get in touch</h1>
        <Form />
      </Container>
    </Section>
  );
};

export default Contact;
