import styled from "styled-components";
import assets from "./assets/assets";

const Section = styled.section`
  background-color: white;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 120px 0 147px;

  h2 {
    color: var(--secondary);
    font-family: var(--body);
    font-weight: 700;
    font-size: 16.5px;
    line-height: 179%;
    letter-spacing: 0.1em;
    margin-top: 0;
    margin-bottom: 85px;
  }

  @media (max-width: 1279px) {
    padding: 95px 150px 120px 145px;
    max-width: none;

    h2 {
      margin-bottom: 80px;
    }
  }

  @media (max-width: 1023px) {
    padding: 66px 48px 106px 45px;
    max-width: 500px;
    h2 {
      margin-bottom: 72px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 75px 95px;
  align-items: center;
  justify-content: center;
  img {
    width: 140px;
    min-width: 0;
    height: 90px;
    object-fit: contain;
  }

  @media (max-width: 1279px) {
    grid-gap: 40px 65px;
    img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, auto);
    grid-gap: 68px 65px;
    justify-content: flex-start;
    img {
      max-height: 80px;
    }
  }
`;

const Clients = () => {
  return (
    <Section>
      <Container>
        <h2>CLIENTS</h2>
        <Grid>
          {assets.map((asset) => (
            <img key={asset.alt} src={asset.src} alt={asset.alt} title={asset.alt} />
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default Clients;
