import { Fragment } from "react";
import styled from "styled-components";
import QuoteCard from "./QuoteCard";

const quotes = [
  [
    {
      quote:
        "The agency behind Biden’s instagram influencer campaign strategy explains why its approach is the opposite of Bloomberg’s meme-buying spree",
      author: "BUSINESS INSIDER",
      link: "https://www.businessinsider.com/inside-bidens-instagram-influencer-strategy-virtual-campaign-2020-6",
    },
    {
      quote:
        "Village Marketing is a specialized agency that delves into the cutting edge.",
      author: "FAST COMPANY",
      link: "https://www.fastcompany.com/90325270/the-woman-behind-the-instagram-influencer-penthouse-has-a-new-brainstorm-turn-influencers-into-creative-directors",
    },
  ],
  [
    {
      quote:
        "Last week, a contributer picked a fight with influencer marketing. This week, an industry exec comes back swinging. Bust open the popcorn...",
      author: "AD AGE",
      link: "https://adage.com/article/opinion/opinion-about-article-calling-influencers-dishonest-and-wasteful/2229691",
    },
    {
      quote: "The 15 hottest influencer-marketing agencies",
      author: "BUSINESS INSIDER",
      link: "https://www.businessinsider.com/inside-bidens-instagram-influencer-strategy-virtual-campaign-2020-6",
    },
  ],
  [
    {
      quote:
        "Vickie Segar, the 35-year-old founder of Village Marketing, came up with the idea for an apartment tailored to social media (Village Studio)",
      author: "THE NEW YORK TIMES",
      large: true,
      link: "https://www.nytimes.com/2018/09/30/business/media/instagram-influencers-penthouse.html",
    },
  ],
];

const Container = styled.section`
  width: 100%;
  background-color: var(--secondary);
`;
const QuotesContainer = styled.div`
  display: table;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 104px 125px 136px 146px;

  @media (max-width: 1279px) {
    padding: 126px 135px 114px 146px;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 36px 0 44px;
  }
`;

const Column = styled.div`
  display: table-cell;
  flex-direction: column;
  padding: 20px 43px;
  position: relative;
  :not(:last-of-type) {
    border-right: 1px solid black;
  }

  :last-of-type {
    padding-left: 60px;
    padding-right: 0;
  }
  :first-of-type {
    padding-left: 0;
  }

  @media (max-width: 1279px) {
    :last-of-type {
      display: none;
    }
    :nth-last-of-type(2) {
      border-right: none;
      padding-right: 0;
    }
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    :not(:last-of-type) {
      border-right: none;
    }
  }
`;

const Spacer = styled.div`
  height: 74px;
  :last-child {
    display: none;
  }
  @media (max-width: 1023px) {
    height: 60px;
    :last-child {
      display: block;
    }
  }
`;

const CompanyQuotes = () => {
  return (
    <Container>
      <QuotesContainer>
        {quotes.map((quoteArray, i) => {
          return (
            <Column key={`column-${i}`}>
              {quoteArray.map((quote, j) => {
                return (
                  <Fragment key={`${quote.author}-${j}`}>
                    <QuoteCard {...quote} />
                    <Spacer />
                  </Fragment>
                );
              })}
            </Column>
          );
        })}
      </QuotesContainer>
    </Container>
  );
};

export default CompanyQuotes;
