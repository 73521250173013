import styled from "styled-components";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as TikTok } from "../../assets/icons/tiktok.svg";
import { ReactComponent as LinkedIn } from "../../assets/icons/linkedin.svg";
import NavLink from "../Header/NavLink";

const navLinks = [
  {
    type: "image",
    path: "https://www.instagram.com/villagemarketing/",
    image: Instagram,
    newTab: true,
  },
  {
    type: "image",
    path: "https://www.tiktok.com/@villagemarketing",
    image: TikTok,
    newTab: true,
  },
  {
    type: "image",
    path: "https://www.linkedin.com/company/village-marketing/",
    image: LinkedIn,
    newTab: true,
  },
];

const StyledFooter = styled.footer`
  height: var(--footer-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  small {
    font-size: 10.5px;
    font-family: var(--body);
    font-weight: 300;
    letter-spacing: 0.15em;
    color: #b8b8b8;
    margin-top: 35px;
  }

  div > a {
    :not(:last-child) {
      margin-right: 50px;
    }
  }
`;
const Footer = () => {
  return (
    <StyledFooter>
      <div>
        {navLinks.map((link) => (
          <NavLink key={link.path} link={link} />
        ))}
      </div>
      <small>© {new Date().getFullYear()} VILLAGE MARKETING. ALL RIGHTS RESERVED.</small>
    </StyledFooter>
  );
};
export default Footer;
