import styled from "styled-components";
import { ReactComponent as QuoteIcon } from "../../assets/icons/quote.svg";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 322px;
  margin: 0 auto;
  q {
    font-size: ${(props) => (props.large ? "34.5px" : "20px")};
    font-family: var(--heading);
    font-weight: 400;
    font-style: italic;
    line-height: ${(props) => (props.large ? "145%" : "147%")};
    letter-spacing: -0.01em;
    color: black;
    margin-top: 16px;
    quotes: none;
  }
  p {
    font-size: 16.5px;
    font-family: var(--body);
    font-weight: 700;
    line-height: 179%;
    letter-spacing: 0.1em;
    color: white;
    margin-bottom: 0;
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  @media (max-width: 1279px) {
    max-width: none;
  }

  @media (max-width: 1023px) {
    max-width: 295px;
  }
`;

const QuoteCard = ({ quote, author, link, large = false }) => {
  return (
    <Card large={large}>
      <QuoteIcon />
      <q>{quote}</q>
      <p>
        <a href={link} target="_blank" rel="noreferrer noopener">
          {author}
        </a>
      </p>
    </Card>
  );
};

export default QuoteCard;
