import axios from "axios";
import { useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  div {
    width: 100%;
    display: flex;
  }
  input,
  textarea,
  button {
    font-size: 13.75px;
    font-family: var(--body);
    font-weight: 600;
    line-height: 215%;
    letter-spacing: 0.08em;
    color: black;
    padding: 11px 20px 7px;
    width: 100%;
    border: 1px solid black;
    margin-bottom: 10px;

    ::placeholder {
      color: black;
      opacity: 0.4;
    }

    &.submit {
      font-size: 16px;
      line-height: 184%;
      margin: 0;
      color: white;
      background-color: black;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 59px;
    }

    @media (max-width: 1023px) {
      font-size: 12px;
    }
  }

  textarea {
    min-height: 188px;
    height: 188px;
    resize: vertical;
  }
  .success {
    font-family: var(--body);
    color: white;
    width: 100%;
    display: inline-block;
    font-weight: 700;
    letter-spacing: 0.08em;
    font-size: 11px;
    text-transform: uppercase;
    margin-top: 10px;
  }
`;

const Modal = styled.div`
  position: fixed;
  padding: 10px 20px;
  background-color: #be2c2c;
  color: white;
  font-family: var(--body);
  font-size: 12px;
  letter-spacing: 0.08em;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  width: auto !important;
  animation: ${fadeIn} 0.25s ease;
  white-space: nowrap;
`;

const Form = () => {
  const ref = useRef();
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    const formData = new FormData(ref.current);

    const dataObj = [...formData.entries()].reduce((acc, cur) => {
      return { ...acc, [cur[0]]: cur[1] };
    }, {});

    const axiosOptions = {
      url: "/",
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      params: dataObj,
    };

    axios(axiosOptions)
      .then(() => {
        setFadeOut(true);
        setTimeout(() => {
          setSubmitting(false);
          setFadeOut(false);
          setSuccess(true);
          ref.current?.reset();
          setTimeout(() => {
            setFadeOut(true);
            setTimeout(() => {
              setSuccess(false);
              setFadeOut(false);
            }, 500);
          }, 3000);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };
  return (
    <StyledForm
      ref={ref}
      method="POST"
      netlify
      name="contact"
      onSubmit={(e) => handleSubmit(e)}
    >
      <input type="hidden" name="form-name" value="contact" />
      <div>
        <input
          name="first-name"
          id="first-name"
          type="text"
          placeholder="FIRST NAME"
          required
          style={{ marginRight: "10px" }}
        />
        <input
          name="last-name"
          id="last-name"
          type="text"
          placeholder="LAST NAME"
          required
        />
      </div>
      <input
        name="email"
        id="email"
        type="email"
        placeholder="EMAIL"
        required
      />
      <input
        name="Username/Business"
        id="subject"
        type="text"
        placeholder="@USERNAME / BUSINESS"
        required
      />
      <textarea
        name="message"
        id="message"
        rows="10"
        placeholder="MESSAGE"
        required
      ></textarea>
      <button type="submit" className="submit" disabled={success || submitting}>
        {submitting ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              opacity: fadeOut ? 0 : 1,
              transform: fadeOut ? "scale(0.9)" : "scale(1)",
              transition: "opacity 0.3s ease-out, transform 0.3s ease-out",
            }}
          >
            <div className="loader" />
          </div>
        ) : success ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              opacity: fadeOut ? 0 : 1,
              transform: fadeOut ? "scale(0.9)" : "scale(1)",
              transition: "opacity 0.3s ease-out, transform 0.3s ease-out",
            }}
          >
            <svg class="animated-check" viewBox="0 0 24 24">
              <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
            </svg>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              animation: "fadeIn 0.2s ease-out",
            }}
          >
            SUBMIT
          </div>
        )}
      </button>
      <span
        className="success"
        style={{
          opacity: success && fadeOut ? 0 : success ? 1 : 0,
          transition: "opacity 0.3s ease",
        }}
      >
        Thank you for your message. It has been sent.
      </span>
      {error && <Modal>There was an error. Please try again later.</Modal>}
    </StyledForm>
  );
};

export default Form;
