import ByTheNumbers from "./components/ByTheNumbers";
import Clients from "./components/Clients";
import CompanyQuotes from "./components/CompanyQuotes";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header/Header";
import Hero from "./components/Hero";
import WhatWeDo from "./components/WhatWeDo";

function App() {
  return (
    <>
      <Header />
      <Hero />
      <ByTheNumbers />
      <CompanyQuotes />
      <WhatWeDo />
      <Clients />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
