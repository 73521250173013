import styled from "styled-components";
import NumberCard from "./NumberCard";

const cards = [
  {
    number: 33,
    unit: null,
    superscript: "%",
    subtitle: "CLIENTS' #1 ACQUISITION CHANNEL",
  },
  {
    number: 8,
    unit: null,
    superscript: null,
    subtitle: "YEARS OF COLLECTED DATA",
  },
  {
    number: 2,
    unit: null,
    superscript: null,
    subtitle: "PRESIDENTS ON OUR CLIENT ROSTER",
  },
  {
    number: "500",
    unit: "K",
    superscript: "+",
    subtitle: "SOCIAL POSTS EXECUTED",
  },
];

const Container = styled.section`
  width: 100%;
  height: 420px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: var(--heading);
    font-weight: 100;
    font-size: 60px;
    line-height: 110%;
    letter-spacing: -0.01em;
    max-width: 220px;
    margin: 0;
    margin-right: 70px;
  }

  @media (max-width: 1279px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 60px;
    padding-bottom: 92px;
    h1 {
      max-width: none;
      margin-right: 0;
      margin-bottom: 61px;
    }
  }

  @media (max-width: 1023px) {
    height: 489px;
    padding-top: 87px;
    padding-bottom: 87px;

    h1 {
      font-size: 40px;
      margin-bottom: 22px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 233px 184px 191px 277px;

  @media (max-width: 1023px) {
    grid-template-columns: 128px 128px;
    grid-template-rows: 128px 128px;
  }
`;
const ByTheNumbers = () => {
  return (
    <Container>
      <h1>By the numbers.</h1>
      <Grid>
        {cards.map((card, i) => (
          <NumberCard key={`${card.subtitle}-${i}`} {...card} />
        ))}
      </Grid>
    </Container>
  );
};

export default ByTheNumbers;
