/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components";
import CountUp from "react-countup";
import { useEffect, useRef, useState } from "react";
import useOnScreen from "../../hooks/use-on-screen";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;

  :nth-of-type(1) {
    p {
      max-width: 150px;
    }
  }
  :nth-of-type(2) {
    p {
      max-width: 139px;
    }
  }
  :nth-of-type(3) {
    p {
      max-width: 132px;
    }
  }
  :nth-of-type(4) {
    margin-left: 50px;
    p {
      max-width: 122px;
    }
  }
  :not(:last-of-type) {
    border-right: 1px solid black;
  }
  p {
    font-size: 10.5px;
    font-family: var(--body);
    font-weight: 700;
    line-height: 105%;
    text-align: center;
    letter-spacing: 0.15em;
    margin: 0;
  }

  @media (max-width: 1023px) {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    :nth-child(1) {
      padding-right: 20px !important;
      ::after {
        content: "";
        width: 1px;
        height: 100px;
        background-color: black;
        position: absolute;
        right: 0;
        top: 40%;
        transform: translateY(-50%);
      }
      p {
        max-width: 99px;
      }
    }
    :nth-child(2) {
      padding-left: 20px !important;
      p {
        max-width: 105px;
      }
    }
    :nth-child(3) {
      padding-right: 20px !important;
      ::after {
        content: "";
        width: 1px;
        height: 100px;
        background-color: black;
        position: absolute;
        right: 0;
        top: 60%;
        transform: translateY(-50%);
      }
      ::before {
        content: "";
        width: 100px;
        height: 1px;
        background-color: black;
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        top: 0;
      }
      p {
        max-width: 96px;
      }
    }
    :nth-child(4) {
      padding-left: 20px !important;

      ::after {
        content: "";
        width: 100px;
        height: 1px;
        background-color: black;
        position: absolute;
        left: 60%;
        transform: translateX(-50%);
        top: 0;
      }
      div {
        position: relative;
        left: 5px;
      }
      p {
        max-width: 83px;
      }
    }
    p {
      font-size: 8px;
    }
  }
`;
const Value = styled.div`
  font-family: var(--heading);
  font-weight: 600;
  font-size: 88px;
  letter-spacing: -0.01em;
  color: var(--secondary);
  position: relative;

  sup {
    font-size: 52px;
  }
  @media (max-width: 1023px) {
    font-size: 40px;
    sup {
      font-size: 30px;
    }
  }
`;
const NumberCard = ({ number, unit, superscript, subtitle }) => {
  const value = useRef();
  const onScreen = useOnScreen(value);
  const startButton = useRef();

  const [triggeredOnce, setTriggeredOnce] = useState(false);
  useEffect(() => {
    if (onScreen && !triggeredOnce) {
      startButton.current?.click();
      setTriggeredOnce(true);
    }
  }, [onScreen]);
  return (
    <Card>
      <Value ref={value}>
        <CountUp end={parseInt(number)} duration={3} startOnMount={false}>
          {({ countUpRef, start }) => (
            <>
              <span ref={countUpRef} />
              <button
                style={{ display: "none" }}
                ref={startButton}
                onClick={start}
              />
            </>
          )}
        </CountUp>
        {unit}
        {superscript && <sup>{superscript}</sup>}
      </Value>
      <p>{subtitle}</p>
    </Card>
  );
};

export default NumberCard;
