import Client1 from "./1.png";
import Client2 from "./2.png";
import Client3 from "./3.png";
import Client4 from "./4.png";
import Client5 from "./5.png";
import Client6 from "./6.png";
import Client7 from "./7.png";
import Client8 from "./8.png";
import Client9 from "./9.png";
import Client10 from "./10.png";
import Client11 from "./11.png";
import Client12 from "./12.png";
import Client13 from "./13.png";
import Client14 from "./14.png";
import Client15 from "./15.png";
import Client16 from "./16.png";
import Client17 from "./17.png";
import Client18 from "./18.png";
import Client19 from "./19.png";
import Client20 from "./20.png";

const assets = [
  { src: Client1, alt: "Amazon" },
  { src: Client2, alt: "Equinox" },
  { src: Client3, alt: "Nécessaire" },
  { src: Client4, alt: "Boll and Branch" },
  { src: Client5, alt: "Venmo" },
  { src: Client6, alt: "Anheuser-Busch" },
  { src: Client7, alt: "SOULCYCLE" },
  { src: Client8, alt: "Spotify" },
  { src: Client9, alt: "Care / Of" },
  { src: Client10, alt: "Tommy John" },
  { src: Client11, alt: "RTR" },
  { src: Client12, alt: "Biden" },
  { src: Client13, alt: "The Farmer's Dog" },
  { src: Client14, alt: "The White House" },
  { src: Client15, alt: "Netflix" },
  { src: Client16, alt: "Harry's" },
  { src: Client17, alt: "The Honest Co." },
  { src: Client18, alt: "Olipop" },
  { src: Client19, alt: "Love Wellness" },
  { src: Client20, alt: "Everlane" },
];

export default assets;
