/* eslint-disable jsx-a11y/alt-text */
import styled, { keyframes } from "styled-components";
import HeroOne from "./assets/hero-1.jpg";
import HeroTwo from "./assets/hero-2.jpg";
import HeroThree from "./assets/hero-3.jpg";
import HeroFour from "./assets/hero-4.jpg";
import HeroFive from "./assets/hero-5.jpg";
import { ReactComponent as PlayButton } from "../../assets/icons/play-button.svg";
import VideoModal from "../Generic/VideoModal";
import { useEffect, useState } from "react";
import video from "../../assets/village-marketing-showcase.mp4";

const hover = keyframes`
0% {
    transform: scale(1)
}
50% {
    transform: scale(1.05)
}
100% {
    transform: scale(1)
}
`;
const Container = styled.section`
  height: 830px;
  margin-top: calc(-1 * var(--header-height));
  padding: 111px 66px 84px 52px;

  @media (max-width: 1023px) {
    margin: 0;
    height: 542px;
    padding: 0;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 906px;
  margin: 0 auto;
  position: relative;
  left: 35px;

  .image-container {
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s ease;
    &.loaded {
      opacity: 1;
    }
    img {
      opacity: 0.55;
      width: 100%;
      animation: ${hover} 6s cubic-bezier(0.25, 0, 0.66, 1) infinite;
    }
    :nth-child(1) {
      width: 301px;
      top: 0;
      right: 216px;
      img {
        animation-delay: 0s;
      }
      @media (max-width: 1023px) {
        width: 252px;
        right: -80px;
      }
    }
    :nth-child(2) {
      width: 300px;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      img {
        animation-delay: -2s;
      }
      @media (max-width: 1023px) {
        width: 228px;
        right: -130px;
      }
    }
    :nth-child(3) {
      width: 311px;
      bottom: -9px;
      right: 189px;
      img {
        animation-delay: -1s;
      }
      @media (max-width: 1023px) {
        width: 264px;
        bottom: 0;
        right: -110px;
      }
    }
    :nth-child(4) {
      width: 286px;
      bottom: 89px;
      left: 25px;
      img {
        animation-delay: -0.5s;
      }
      @media (max-width: 1023px) {
        width: 217px;
        left: -75px;
      }
    }
    :nth-child(5) {
      width: 292px;
      top: 136px;
      left: -15px;
      img {
        animation-delay: -3s;
      }
      @media (max-width: 1023px) {
        width: 252px;
        top: 98px;
        left: -120px;
      }
    }
  }

  @media (max-width: 1023px) {
    left: 0;
    max-width: 600px;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 50px;
  position: absolute;
  color: white;
  text-align: center;
  h1 {
    font-size: 71px;
    line-height: 106%;
    font-family: var(--heading);
    font-weight: 100;
    max-width: 442px;
    margin: 0;
  }
  button {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    width: 103.5px;
    height: 103.5px;
    margin: 35px 0;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  p {
    font-family: var(--body);
    font-weight: 300;
    line-height: 105%;
    letter-spacing: 0.15em;
    font-size: 21px;
    max-width: 455px;
    margin: 0;
  }

  @media (max-width: 1023px) {
    button {
      width: 80px;
      height: 80px;
      margin-top: 31px;
      margin-bottom: 43px;
    }
    h1 {
      font-size: 48px;
      max-width: 321px;
    }
    p {
      font-size: 17px;
      line-height: 129%;
      max-width: 278px;
    }
  }
`;

const Hero = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loadedImages, setLoadedImages] = useState([]);
  useEffect(() => {
    document.body.style.overflow = modalOpen ? "hidden" : "auto";
  });

  return (
    <Container>
      {modalOpen && (
        <VideoModal
          url={video}
          closeModal={() => setModalOpen(false)}
          modalOpen={modalOpen}
        />
      )}
      <ContentContainer>
        <div
          className={`image-container ${
            loadedImages.includes(1) ? "loaded" : null
          }`}
        >
          <img
            src={HeroOne}
            onLoad={() => setLoadedImages([...loadedImages, 1])}
          />
        </div>
        <div
          className={`image-container ${
            loadedImages.includes(2) ? "loaded" : null
          }`}
        >
          <img
            src={HeroTwo}
            onLoad={() => setLoadedImages([...loadedImages, 2])}
          />
        </div>
        <div
          className={`image-container ${
            loadedImages.includes(3) ? "loaded" : null
          }`}
        >
          <img
            src={HeroThree}
            onLoad={() => setLoadedImages([...loadedImages, 3])}
          />
        </div>
        <div
          className={`image-container ${
            loadedImages.includes(4) ? "loaded" : null
          }`}
        >
          <img
            src={HeroFour}
            onLoad={() => setLoadedImages([...loadedImages, 4])}
          />
        </div>
        <div
          className={`image-container ${
            loadedImages.includes(5) ? "loaded" : null
          }`}
        >
          <img
            src={HeroFive}
            onLoad={() => setLoadedImages([...loadedImages, 5])}
          />
        </div>
        <TextContainer>
          <h1>
            We Build <span className="accent">Brands Through People.</span>
          </h1>
          <button onClick={() => setModalOpen(true)}>
            <PlayButton />
          </button>
          <p>
            We are a performance-driven influencer shop, fueled by creativity
            and powered by relationships.
          </p>
        </TextContainer>
      </ContentContainer>
    </Container>
  );
};

export default Hero;
