/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useOnScreen from "../../hooks/use-on-screen";

const listItems = [
  "SOCIAL STRATEGY",
  "INFLUENCER MARKETING",
  "AFFILIATE MARKETING",
  "CONTENT USAGE MANAGEMENT",
  "CONTENT PRODUCTION",
];

const Section = styled.section`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 188px 0 246px;

  h1 {
    font-size: 71px;
    line-height: 106%;
    font-family: var(--heading);
    font-weight: 100;
    color: white;
    max-width: 700px;
    position: relative;
    margin: 0;
    margin-top: 27px;
    ::before {
      content: "WHAT WE DO";
      --subtitle-height: 30px;
      position: absolute;
      font-family: var(--body);
      font-size: 16.5px;
      font-weight: 700;
      line-height: 179%;
      letter-spacing: 0.1em;
      top: calc(-1 * var(--subtitle-height) - 27px);
      left: 0;
      height: var(--subtitle-height);
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 48px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 48px;
    border-left: 1px solid white;
    li {
      font-size: 13.75px;
      line-height: 215%;
      font-family: var(--body);
      font-weight: 700;
      letter-spacing: 0.08em;
      color: white;
      opacity: ${(props) => (props.triggeredOnce ? 1 : 0)};
      transform: ${(props) =>
        props.triggeredOnce ? "translateX(0)" : "translateX(-10px)"};
      transition: opacity 0.5s ease-in, transform 0.5s ease;
      :not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  @media (max-width: 1279px) {
    padding: 100px 0 89px;
    flex-direction: column;
    align-items: flex-start;
    ul {
      margin: 0;
      padding: 0;
      border-left: none;
      margin-top: 60px;
    }
  }

  @media (max-width: 1023px) {
    padding: calc(66px * 2) 44px 114px 44px;
    h1 {
      font-size: 48px;
      line-height: 108%;
      max-width: 500px;
      ::before {
        top: calc(-1 * var(--subtitle-height) - 66px);
      }
    }

    ul {
      margin-top: 50px;
    }
  }
`;

const WhatWeDo = () => {
  const ref = useRef();
  const onScreen = useOnScreen(ref);
  const [triggeredOnce, setTriggeredOnce] = useState(false);
  useEffect(() => {
    if (onScreen && !triggeredOnce) setTriggeredOnce(true);
  }, [onScreen]);

  return (
    <Section id="services">
      <Container triggeredOnce={triggeredOnce}>
        <h1>
          We <span className="accent">leverage influencers</span> to{" "}
          <span className="accent">deliver business results</span> for our
          clients
        </h1>
        <ul ref={ref}>
          {listItems.map((item, i) => (
            <li key={item + i} style={{ transitionDelay: `${i * 0.4}s` }}>
              {item}
            </li>
          ))}
        </ul>
      </Container>
    </Section>
  );
};

export default WhatWeDo;
