import { useState } from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const slideUp = keyframes`
    from {
        transform: translateY(10px);
    }
    to {
        transform: translateY(0px);
    }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.4s ease;
  display: ${(props) => (props.videoLoaded ? "flex" : "none")};
  &.fadeOut {
    animation: ${fadeOut} 0.4s ease;
  }
  .modal-content {
    width: 100%;
    max-width: 900px;
    width: 90vw;
    /* padding: 20px; */
    z-index: 1;
    position: relative;
    animation: ${slideUp} 0.4s ease;
    button {
      position: absolute;
      top: -50px;
      right: -35px;
      background-color: transparent;
      padding: 0;
      margin: 0;
      border: none;
      color: white;
      font-size: 40px;
      font-weight: 100;
      font-family: var(--heading);
      @media (max-width: 1023px) {
        display: none;
      }
    }
  }
  .off-click {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
const VideoModal = ({ url, closeModal, modalOpen }) => {
  const [fadeOut, setFadeOut] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleCloseModal = () => {
    setFadeOut(true);
    setTimeout(() => {
      closeModal();
      setVideoLoaded(false);
    }, 400);
  };

  return (
    <ModalContainer
      modalOpen={modalOpen}
      videoLoaded={videoLoaded}
      className={fadeOut ? "fadeOut" : null}
    >
      <div className="modal-content">
        <button onClick={handleCloseModal}>×</button>
        <video
          onLoadedData={() => setVideoLoaded(true)}
          playsInline
          autoPlay
          muted
          controls
          preload="auto"
          style={{ width: "100%", height: "100%" }}
          src={url}
        ></video>
      </div>
      <div onClick={handleCloseModal} className="off-click" />
    </ModalContainer>
  );
};
export default VideoModal;
