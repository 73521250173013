import styled from "styled-components";

const StyledLink = styled.a`
  font-size: 10.5px;
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "white")};
  letter-spacing: 0.15em;
  text-decoration: none;
  font-family: var(--body);
  font-weight: 300;
`;
const NavLink = ({ link }) => {
  const { type, path, text, newTab } = link;
  return (
    <StyledLink
      href={path}
      color={link.color ? link.color : null}
      target={newTab ? "__blank" : null}
    >
      {type === "text" ? text : link.image ? <link.image /> : null}
    </StyledLink>
  );
};

export default NavLink;
